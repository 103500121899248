import React from 'react';
import { Global } from '@theme';
import { Layout } from '@components';
import { SEO } from '@components';
import {
  InitialSection,
  ContactSection,
  SolutionsSection,
  StepsSection,
  TechnologiesSection,
  DifferentialsSection,
  CasesSection,
} from '@screens/Main';

const IndexPage = () => (
  <>
    <Global />
    <Layout>
      <SEO title="Desenvolvimento de software sem complicação - Diagnóstico Projeto" />
      <InitialSection text="Precisa de apoio para o desenvolvimento de software da sua empresa? Vamos te dar um help para tirar sua ideia do papel!"/>
      <SolutionsSection />
      <StepsSection />
      <TechnologiesSection />
      <CasesSection />
      <DifferentialsSection />
      <ContactSection />
    </Layout>
  </>
);

export default IndexPage;
